import "vue-resize/dist/vue-resize.css";
import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import store from "./vuex/store.js";
import router from "./router/index.js";
import App from "./App.vue";
import VueResize from "vue-resize";
import ImagePreview from "./components/ImagePreview";
import "./registerServiceWorker";
import { Search, Popover } from "vant";
import "@vant/touch-emulator";
import VueSocialSharing from "vue-social-sharing";
import VueI18n from "vue-i18n";
import translationEN from "@/assets/i18n/en-US.json";
import translationCN from "@/assets/i18n/zh-CN.json";
import VueMeta from "vue-meta";
import VueCookie from "vue-cookie";

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueResize);
Vue.use(ImagePreview);
Vue.use(Search);
Vue.use(Popover);
Vue.use(VueSocialSharing);
Vue.use(VueI18n);
Vue.use(VueMeta, { refreshOnceOnNavigation: true });
Vue.use(VueCookie);
Vue.config.productionTip = false;

/**
 * Get the browser language
 */
export const browserLang = (() => {
  const supports = ["en", "zh"];
  const langs = {
    en: "en-US",
    zh: "zh-CN",
  };
  const userLang = window.navigator.language || window.navigator.userLanguage;
  let lang = supports[0];
  if (userLang) {
    const useLang = userLang.trim().slice(0, 2);
    if (useLang in langs) {
      lang = useLang;
    }
  }
  // Keep displaying in English
  lang = supports[0];
  return langs[lang];
})();

const i18n = new VueI18n({
  locale: browserLang,
  // GitHub Issue: https://github.com/kazupon/vue-i18n/issues/89
  // Using en and zh would cause the warning, while "en-US" and "zh-CN" wouldn't.
  // messages: { en: translationEN, zh: translationCN },
  messages: { "en-US": translationEN, "zh-CN": translationCN },
});

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
