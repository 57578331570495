import Vue from "vue";
import VueRouter from "vue-router";
import store from "../vuex/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("../components/HomePage.vue"),
      children: [
        {
          path: "",
          name: "Homepage",
          component: () => import("../components/ExhibitionContainer"),
        },
      ],
    },
    {
      path: "/genre/:genre",
      component: () => import("../components/HomePage.vue"),
      children: [
        {
          path: "",
          name: "Genre",
          component: () => import("../components/ExhibitionContainer"),
        },
      ],
      beforeEnter: (to, from, next) => {
        const { genre } = to.params;
        console.log("Go to genre", genre);
        if (genre && genre in store.state.imagesData) {
          store.dispatch("switchGenre", genre);
        }
        next();
      },
    },
    {
      path: "/painting/:paintingIndex",
      component: () => import("../components/HomePage.vue"),
      children: [
        {
          path: "",
          name: "Painting",
          component: () => import("../components/SoloContainer"),
          props: (route) => ({ paintingIndex: route.params.paintingIndex }),
        },
      ],
    },
    // {
    //   path: "*",
    //   redirect: "/",
    // },
  ],
});

export default router;
