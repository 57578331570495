<template>
  <span :title="title ? title : ''">
    <svg class="svg-icon" aria-hidden="true" @click="handleClick">
      <use :xlink:href="iconName" />
    </svg>
  </span>
</template>

<script>
import "@/components/ImagePreview/font";
export default {
  name: "SvgIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.name}`;
    },
  },
  methods: {
    handleClick() {
      this.$listeners.click && this.$listeners.click();
    },
  },
};
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
