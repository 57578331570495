import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { get } from "lodash";

Vue.use(Vuex);

const genreList = [
  "Others",
  "Surrealism",
  "Fauvism",
  "Neo-Plasticism",
  "Futurism",
  "Cubism",
  "Expressionism",
  "Les Nabis",
  "Art Nouveau",
  "Pointillism",
  "Post-Impressionism",
  "Impressionism",
  "The Barbizon School",
  "Realism",
  "Symbolism",
  "Classicism",
  "The Pre-Raphaelite Brotherhood",
  "Romanticism",
  "Academic Art",
  "Neoclassicism",
  "Rococo Art",
  "Baroque Art",
  "Mannerism",
  "The Booming Renaissance",
  "The Northern Renaissance",
  "The Early Renaissance",
  "Gothic Art",
];

// const initialGenre = genreList[Math.floor(Math.random() * genreList.length)];
// Insist on my own aestentics, Neo-Plasticism -> Post-Impressionism
const initialGenre = genreList[10];

const store = new Vuex.Store({
  state: {
    fullscreen: false,
    share: window.location.search.includes("share"),
    genre: initialGenre,
    searchResult: [],
    showCollection: false,
    shrinkLeftMenu: true,
    hardwareBackActive: false,
    modalVisible: false, // related to hardwareBackActive event
    collectionResult: [],
    activeSinglePainting: {},
    imagesData: {
      Others: [],
      Surrealism: [],
      Fauvism: [],
      "Neo-Plasticism": [],
      Futurism: [],
      Cubism: [],
      Expressionism: [],
      "Les Nabis": [],
      "Art Nouveau": [],
      Pointillism: [],
      "Post-Impressionism": [],
      Impressionism: [],
      "The Barbizon School": [],
      Realism: [],
      Symbolism: [],
      Classicism: [],
      "The Pre-Raphaelite Brotherhood": [],
      Romanticism: [],
      "Academic Art": [],
      Neoclassicism: [],
      "Rococo Art": [],
      "Baroque Art": [],
      Mannerism: [],
      "The Booming Renaissance": [],
      "The Northern Renaissance": [],
      "The Early Renaissance": [],
      "Gothic Art": [],
    },
    leftHiddenImagesStack: {
      Others: [],
      Surrealism: [],
      Fauvism: [],
      "Neo-Plasticism": [],
      Futurism: [],
      Cubism: [],
      Expressionism: [],
      "Les Nabis": [],
      "Art Nouveau": [],
      Pointillism: [],
      "Post-Impressionism": [],
      Impressionism: [],
      "The Barbizon School": [],
      Realism: [],
      Symbolism: [],
      Classicism: [],
      "The Pre-Raphaelite Brotherhood": [],
      Romanticism: [],
      "Academic Art": [],
      Neoclassicism: [],
      "Rococo Art": [],
      "Baroque Art": [],
      Mannerism: [],
      "The Booming Renaissance": [],
      "The Northern Renaissance": [],
      "The Early Renaissance": [],
      "Gothic Art": [],
    },
    rightHiddenImagesStack: {
      Others: [],
      Surrealism: [],
      Fauvism: [],
      "Neo-Plasticism": [],
      Futurism: [],
      Cubism: [],
      Expressionism: [],
      "Les Nabis": [],
      "Art Nouveau": [],
      Pointillism: [],
      "Post-Impressionism": [],
      Impressionism: [],
      "The Barbizon School": [],
      Realism: [],
      Symbolism: [],
      Classicism: [],
      "The Pre-Raphaelite Brotherhood": [],
      Romanticism: [],
      "Academic Art": [],
      Neoclassicism: [],
      "Rococo Art": [],
      "Baroque Art": [],
      Mannerism: [],
      "The Booming Renaissance": [],
      "The Northern Renaissance": [],
      "The Early Renaissance": [],
      "Gothic Art": [],
    },
    lastLeftPushImagesRecords: [],
    lastRightPushImagesRecords: [],
  },

  mutations: {
    TOGGLE_FULLSCREEN(state) {
      state.fullscreen = !state.fullscreen;
    },

    UPDATE_HARDWARE_BACK_ACTIVE(state, data) {
      state.hardwareBackActive = data;
    },

    UPDATE_MODAL_VISIBLE(state, data) {
      state.modalVisible = data;
    },

    UPDATE_SHRINK_LEFTMENU(state, data) {
      state.shrinkLeftMenu = data;
    },

    UPDATE_ACTIVE_SINGLE_PAINTING(state, painting) {
      state.activeSinglePainting = painting;
    },

    UPDATE_SEARCH_RESULT(state, data) {
      state.searchResult = data;
    },

    TOGGLE_SHOW_COLLECTION(state) {
      state.showCollection = !state.showCollection;
    },

    UPDATE_COLLECTION_RESULT(state, data) {
      state.collectionResult = data;
    },

    SWITCH_GENRE(state, data) {
      let { key } = data;
      state.genre = key;
    },
    INITIALIZE_IMAGES_DATA(state, data) {
      let { key, images } = data;
      state.imagesData[key] = images;
    },
    LEFT_UNSHIFT_IMAGES_DATA(state, data) {
      let { key, images } = data;
      if (Array.isArray(state.leftHiddenImagesStack[key])) {
        images.forEach((image) => {
          state.leftHiddenImagesStack[key].unshift(image);
        });
      } else {
        throw new Error("Mutation LEFT_UNSHIFT_IMAGES_DATA failed");
      }
    },
    RIGHT_UNSHIFT_IMAGES_DATA(state, data) {
      let { key, images } = data;
      if (Array.isArray(state.rightHiddenImagesStack[key])) {
        images.forEach((image) => {
          state.rightHiddenImagesStack[key].unshift(image);
        });
      } else {
        throw new Error("Mutation RIGHT_UNSHIFT_IMAGES_DATA failed");
      }
    },
    LEFT_PUSH_IMAGES_DATA(state, data) {
      let { key, images } = data;
      if (Array.isArray(state.leftHiddenImagesStack[key])) {
        images.forEach((image) => {
          state.leftHiddenImagesStack[key].push(image);
        });
      } else {
        throw new Error("Mutation LEFT_PUSH_IMAGES_DATA failed");
      }
    },
    RIGHT_PUSH_IMAGES_DATA(state, data) {
      let { key, images } = data;
      if (Array.isArray(state.rightHiddenImagesStack[key])) {
        images.forEach((image) => {
          state.rightHiddenImagesStack[key].push(image);
        });
      } else {
        throw new Error("Mutation RIGHT_PUSH_IMAGES_DATA failed");
      }
    },
    LEFT_HIDDEN_IMAGES_STACK_POP(state, data) {
      let { key } = data;
      state.leftHiddenImagesStack[key].pop();
    },
    RIGHT_HIDDEN_IMAGES_STACK_POP(state, data) {
      let { key } = data;
      state.rightHiddenImagesStack[key].pop();
    },
    LEFT_HIDDEN_IMAGES_STACK_SHIFT(state, data) {
      let { key } = data;
      state.leftHiddenImagesStack[key].shift();
    },
    RIGHT_HIDDEN_IMAGES_STACK_SHIFT(state, data) {
      let { key } = data;
      state.rightHiddenImagesStack[key].shift();
    },
    UPDATE_LAST_LEFT_PUSH_IMAGES_RECORDS(state, data) {
      state.lastLeftPushImagesRecords = data;
    },
    UPDATE_LAST_RIGHT_PUSH_IMAGES_RECORDS(state, data) {
      state.lastRightPushImagesRecords = data;
    },
  },

  actions: {
    toggleFullscreen({ commit }) {
      commit("TOGGLE_FULLSCREEN");
    },

    updateHardwareBackActive({ commit }, data) {
      commit("UPDATE_HARDWARE_BACK_ACTIVE", data);
    },

    updateModalVisible({ commit }, data) {
      commit("UPDATE_MODAL_VISIBLE", data);
    },

    updateShrinkLeftMenu({ commit }, data) {
      commit("UPDATE_SHRINK_LEFTMENU", data);
    },

    updateActiveSinglePainting({ commit }, painting) {
      commit("UPDATE_ACTIVE_SINGLE_PAINTING", painting);
    },

    async fetchSinglePainting({ commit }, data) {
      let { index } = data;
      let { data: paintingInfo } = await axios.get(
        `https://api.boost-art.net/v1/painting?index=${index}`
      );
      commit("UPDATE_ACTIVE_SINGLE_PAINTING", paintingInfo["data"][0]);
      return paintingInfo["data"][0];
    },

    async fetchSearchResult({ commit }, searchString) {
      const config = {
        method: "get",
        url: `https://api.boost-art.net/v1/search?q=${searchString}`,
        headers: {},
      };

      const response = await axios(config).catch((err) => {
        throw new Error(err);
      });

      commit("UPDATE_SEARCH_RESULT", get(response.data, "data", []));
      return "success";
    },

    toggleShowCollection({ commit }) {
      commit("TOGGLE_SHOW_COLLECTION");
    },

    async fetchCollectionResult({ commit }, token) {
      const config = {
        method: "post",
        url: `https://api.boost-art.net/v1/getCollection`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          "boost-art-net-token": token,
        },
        withCredentials: true,
      };

      const response = await axios(config).catch((err) => {
        throw new Error(err);
      });

      commit("UPDATE_COLLECTION_RESULT", get(response.data, "data", []));
      return "success";
    },

    async addLikeToCollection({ commit }, { token, index }) {
      const config = {
        method: "post",
        url: `https://api.boost-art.net/v1/like`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          "boost-art-net-token": token,
          "like-id": index,
        },
        withCredentials: true,
      };

      const response = await axios(config).catch((err) => {
        throw new Error(err);
      });

      // false operation on purpose
      commit(
        "UPDATE_COLLECTION_RESULT",
        get(this.state, "collectionResult", [])
      );

      return response?.message === "User like status OK" ? "success" : "";
    },

    async removeLikeFromCollection({ commit }, { token, index }) {
      const config = {
        method: "post",
        url: `https://api.boost-art.net/v1/dislike`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          "boost-art-net-token": token,
          "dislike-id": index,
        },
        withCredentials: true,
      };

      const response = await axios(config).catch((err) => {
        throw new Error(err);
      });

      // false operation on purpose
      commit(
        "UPDATE_COLLECTION_RESULT",
        get(this.state, "collectionResult", [])
      );

      return response?.message === "User dislike status OK" ? "success" : "";
    },

    clearSearchResult({ commit }) {
      commit("UPDATE_SEARCH_RESULT", []);
    },

    clearCollectionResult({ commit }) {
      commit("UPDATE_COLLECTION_RESULT", []);
    },

    switchGenre({ commit }, key) {
      const payLoads = {
        key: key,
      };
      commit("SWITCH_GENRE", payLoads);
    },

    initializeImagesData({ commit }, key) {
      const config = {
        method: "get",
        url: `https://api.boost-art.net/v1/paintings?style=${key}&counts=7`,
        headers: {},
      };

      return new Promise((resolve, reject) => {
        axios(config)
          .then(function (response) {
            const payLoads = {
              key: get(response.data, "style"),
              images: get(response.data, "data"),
            };
            commit("INITIALIZE_IMAGES_DATA", payLoads);
            resolve("success");
            // console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          });
      });
    },

    leftUnshiftImagesData({ commit }, key) {
      const config = {
        method: "get",
        url: `https://api.boost-art.net/v1/paintings?style=${key}&counts=5&lastPushImagesRecords=${JSON.stringify(
          this.state.lastLeftPushImagesRecords
        ).slice(1, -1)}`,
        // like "VanGogh252","Cezanne246"
        // State management to prevent duplicate records in subsequent requests
        // Context: https://github.com/Leslie-Wong-H/Boost-Art-RestAPI/issues/35
        headers: {},
      };

      return new Promise((resolve, reject) => {
        axios(config)
          .then(function (response) {
            console.log("left fill");
            // store the last four for later use
            const payLoads = {
              key: get(response.data, "style"),
              images: get(response.data, "data").slice(0, -1),
            };

            // pop the first one for immediate rendering
            const popData = {
              key: get(response.data, "style"),
              images: get(response.data, "data").slice(-1),
            };

            commit("LEFT_UNSHIFT_IMAGES_DATA", payLoads);
            commit(
              "UPDATE_LAST_LEFT_PUSH_IMAGES_RECORDS",
              get(response.data, "data").map((el) => el["Painting_Index"])
            );
            resolve(popData);
            // console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          });
      });
    },

    rightPushImagesData({ commit }, key) {
      const config = {
        method: "get",
        url: `https://api.boost-art.net/v1/paintings?style=${key}&counts=5&lastPushImagesRecords=${JSON.stringify(
          this.state.lastRightPushImagesRecords
        ).slice(1, -1)}`,
        // like "VanGogh252","Cezanne246"
        // State management to prevent duplicate records in subsequent requests
        // Context: https://github.com/Leslie-Wong-H/Boost-Art-RestAPI/issues/35
        headers: {},
      };

      return new Promise((resolve, reject) => {
        axios(config)
          .then(function (response) {
            console.log("right fill");
            // store the last four for later use
            const payLoads = {
              key: get(response.data, "style"),
              images: get(response.data, "data").slice(0, -1),
            };

            // pop the first one for immediate rendering
            const popData = {
              key: get(response.data, "style"),
              images: get(response.data, "data").slice(-1),
            };

            commit("RIGHT_PUSH_IMAGES_DATA", payLoads);
            commit(
              "UPDATE_LAST_RIGHT_PUSH_IMAGES_RECORDS",
              get(response.data, "data").map((el) => el["Painting_Index"])
            );
            resolve(popData);
            // console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          });
      });
    },

    leftHiddenImagesStackPop({ commit }, key) {
      const payLoads = {
        key: key,
      };
      commit("LEFT_HIDDEN_IMAGES_STACK_POP", payLoads);
    },

    rightHiddenImagesStackPop({ commit }, key) {
      const payLoads = {
        key: key,
      };
      commit("RIGHT_HIDDEN_IMAGES_STACK_POP", payLoads);
    },

    leftHiddenImagesStackShift({ commit }, key) {
      const payLoads = {
        key: key,
      };
      commit("LEFT_HIDDEN_IMAGES_STACK_SHIFT", payLoads);
    },

    rightHiddenImagesStackShift({ commit }, key) {
      const payLoads = {
        key: key,
      };
      commit("RIGHT_HIDDEN_IMAGES_STACK_SHIFT", payLoads);
    },
  },

  getters: {
    getFullscreen(state) {
      return state.fullscreen;
    },

    getShare(state) {
      return state.share;
    },

    getHardwareBackActive(state) {
      return state.hardwareBackActive;
    },

    getModalVisible(state) {
      return state.modalVisible;
    },

    getShrinkLeftMenu(state) {
      return state.shrinkLeftMenu;
    },

    getActiveSinglePainting(state) {
      return state.activeSinglePainting;
    },

    getSearchResult(state) {
      return state.searchResult;
    },

    getShowCollection(state) {
      return state.showCollection;
    },

    getCollectionResult(state) {
      return state.collectionResult;
    },

    getGenre(state) {
      return state.genre;
    },

    getImagesData(state) {
      return state.imagesData;
    },

    getLeftHiddenImagesStack(state) {
      return state.leftHiddenImagesStack;
    },

    getRightHiddenImagesStack(state) {
      return state.rightHiddenImagesStack;
    },
  },
});

export default store;
